import {smarterGet, smarterPost} from '@helpers/vuex/data-loading'
import {makeSetters} from '@helpers/vuex/mutations'

export default {
  namespaced: true,
  state: {
    nutrientRequirements: []
  },
  mutations: {
    ...makeSetters([
      'nutrientRequirements'
    ])
  },
  actions: {
    async loadPrerequisiteData ({rootState, commit}) {
      const {fieldId} = rootState.fieldRecordSystem.navigation.location
      const {harvestYear} = rootState.fieldRecordSystem.userSettings

      await smarterGet(`/api/v2/frs/${harvestYear}/fields/${fieldId}/nutrient-requirements`, {
        id: 'nutrientRequirements.load',
        expiry: 5,
        onResult (nutrientRequirements) {
          commit('setNutrientRequirements', nutrientRequirements)
        }
      })
    },
    async saveManualNutrientRequirement ({state, rootState, commit}, nutrientRequirement) {
      const {fieldId} = rootState.fieldRecordSystem.navigation.location
      const {harvestYear} = rootState.fieldRecordSystem.userSettings

      await smarterPost(`/api/v2/frs/${harvestYear}/fields/${fieldId}/nutrient-requirements/manual`, nutrientRequirement, {
        id: 'nutrientRequirements.save',
        onResult ({id}) {
          nutrientRequirement.id = id
          commit('setNutrientRequirements', [...state.nutrientRequirements, nutrientRequirement])
        }
      })
    }
  }
}
